
export default {
  name: 'HeaderMinicartItems',

  methods: {
    isArray (item) {
      return Array.isArray(item)
    },
  },
}
