import { createNamespacedHelpers } from 'vuex'
import configTypes from '~/store/storeConfig/types'

const {
  mapState: mapStateConfig,
  mapGetters: mapGettersConfig,
} = createNamespacedHelpers('storeConfig')

export default {
  computed: {
    ...mapStateConfig(['megaCategory']),
    ...mapGettersConfig({
      navbarItems: configTypes.GET_MEGAMENU_NAVBAR_ITEMS,
    }),
  },

  methods: {
    getItemsByParentId (parentNodeId) {
      return this.megaCategory.filter(({ parentId }) => parentId === parentNodeId)
    },

    getNodeUrl (node) {
      switch (node.type) {
        case 'product': return node.urlKey ? this.localePath(`/p/${node.urlKey}`) : false
        case 'category': return node.urlKey ? this.localePath(`/c/${node.urlKey}`) : false
        default: return String(node.content).startsWith('http')
          ? node.content ?? ''
          : this.localePath(node.content ?? '')
      }
    },
  },
}
