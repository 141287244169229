import { render, staticRenderFns } from "./HeaderMinicartItems.vue?vue&type=template&id=cea731f8&"
import script from "./HeaderMinicartItems.vue?vue&type=script&lang=js&"
export * from "./HeaderMinicartItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMinicartItemGrouped: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Header/Minicart/HeaderMinicartItemGrouped.vue').default,HeaderMinicartItem: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/Header/Minicart/HeaderMinicartItem.vue').default})
