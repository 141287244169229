
import megaMenuMixin from '~/mixins/megaMenu'

export default {
  name: 'MegaMenuDesktop',

  mixins: [
    megaMenuMixin,
  ],

  props: {
    isNavbarOpen: {
      type: Boolean,
      required: true,
    },

    isHomepage: {
      type: Boolean,
      required: true,
    },

    version: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    navbarId: null,
  }),

  methods: {
    onMenuItemClicked (node) {
      if (this.version > 1 && this.isHomepage) {
        return
      }

      this.openCloseNavbar(node)
    },

    openCloseNavbar (node) {
      this.navbarId = node.nodeId
      this.$emit('navbar-id', node.nodeId)
      this.$emit('toggle-navbar', !this.isNavbarOpen ? node.hasChildren : false)
    },
  },
}
