
export default {
  name: 'HeaderCart',

  provide () {
    return {
      headerCart: this.headerCart,
    }
  },

  data () {
    return {
      headerCart: {
        isOpen: false,
      },
    }
  },

  mounted () {
    this.$root.$on('global-open-mini-cart', this.onOpenMiniCart)
  },

  beforeDestroy () {
    this.$root.$off('global-open-mini-cart', this.onOpenMiniCart)
  },

  methods: {
    onOpenMiniCart () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      this.headerCart.isOpen = true
    },

    onCloseMiniCart () {
      this.headerCart.isOpen = false
    },
  },
}
