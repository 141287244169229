
export default {
  name: 'HeaderMenuIcon',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  head () {
    return {
      bodyAttrs: {
        class: `theme--${this.$config.storeCode} ${this.isOpen ? 'overflow-hidden' : ''}`,
      },
    }
  },
}
