
import categoryPathCreator from '~/mixins/categoryPathCreator'

export default {
  name: 'HeaderMenu',
  mixins: [categoryPathCreator],
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data () {
    return {
      deep: 0,
      overflowX: 'hidden',
      overflowY: 'hidden',
    }
  },

  watch: {
    isOpen () {
      this.deep = 0
    },
  },

  mounted () {
    // remove menu scroll hidding workaround after mount
    this.overflowX = undefined
    this.overflowY = 'auto'
  },

  methods: {
    onCloseNavbar (e) {
      const btnClicked = e.composedPath().some(({ classList }) => classList?.contains('header-menu-icon'))

      if (!btnClicked) {
        this.$emit('close-navbar')
      }
    },

    menuHeight (elements, deep) {
      const currentSubMenu = Object.values(elements).filter(item => item)
      const headerHeight = 70
      const navHeight = (deep === 0 ? this.$refs.mainMenu.clientHeight : currentSubMenu[0].clientHeight) + headerHeight

      this.overflowY = this.$css.breakpoints.height < navHeight ? 'auto' : 'hidden'

      this.$nextTick(() => {
        this.$refs.nav.scrollTop = 0
      })
    },
  },
}
