
import categoryPathCreator from '~/mixins/categoryPathCreator'

export default {
  name: 'HeaderMenuItem',
  mixins: [categoryPathCreator],
  props: {
    item: {
      type: Object,
      required: true,
    },

    parent: {
      type: [Object, null],
      default: null,
    },

    isOpen: {
      type: Boolean,
      required: true,
    },

    deep: {
      type: Number,
      required: true,
    },

    nested: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      isVisible: false,
    }
  },

  watch: {
    isOpen () {
      if (!this.isOpen) {
        this.isVisible = false
      }
    },
  },

  methods: {
    showChild () {
      if (this.$css.breakpoints.mdAndDown) {
        this.$emit('child-deep', this.$refs, this.deep + 1)
        this.isVisible = true
        this.$emit('child-show')
      }
    },

    hideChild () {
      const currentDeep = this.deep - 1

      if (currentDeep === 0) {
        this.$emit('child-deep', this.$refs, currentDeep)
      } else {
        this.$emit('deeper-submenu', this.$refs, currentDeep)
      }

      this.isVisible = false
      this.$emit('child-hide')
    },

    deeperSubmenu () {
      this.$emit('child-deep', this.$refs, this.deep)
    },
  },
}
