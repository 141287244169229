
export default {
  name: 'HeaderLocaleSwitcher',
  data () {
    return {
      isOpen: false,
    }
  },

  methods: {
    toggle () {
      this.isOpen = !this.isOpen
    },

    onClose () {
      this.isOpen = false
    },
  },
}
